import {
  Box,
  Text,
  Button,
  Stack,
  Icon,
  Heading,
  Image,
  chakra,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';

export default function Hero({ data } : { data: any; }) {
  return (
    <Box
      px={8}
      pt={24}
      mx="auto"
    >
      <Box
        w={{
          base: 'full',
          md: 11 / 12,
          xl: 9 / 12,
        }}
        mx="auto"
        textAlign="center"
      >
        <Heading
          as="h1"
          mb={6}
          fontSize={{
            base: '4xl',
            md: '6xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          Me luomme
          <br />
          <Text
            display={{
              base: 'block',
              lg: 'inline',
            }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, green.400,purple.500)"
            fontWeight="extrabold"
            lineHeight="normal"
          >
            helppo
            <chakra.span sx={{
              '@media (min-width: 441px)': {
                display: 'none',
              },
            }}
            >
              -
              <br />
            </chakra.span>
            käyttöisempiä
            <br />
          </Text>
          {' '}
          opetuksen verkko
          <chakra.span sx={{
            '@media (min-width: 356px)': {
              display: 'none',
            },
          }}
          >
            -
            <br />
          </chakra.span>
          palveluita
        </Heading>
        <Text
          px={{
            base: 0,
            lg: 24,
          }}
          mb={6}
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="gray.600"
          _dark={{
            color: 'gray.300',
          }}
        >
          Kehitämme tällä hetkellä nykyaikaista opetuksen työpöytää Pulpettia.
          {' '}
          <br />
          Opetus ansaitsee parempilaatuisia ohjelmistoja, ja me teemme niitä.
        </Text>
        <Stack
          direction={{
            base: 'column',
            sm: 'row',
          }}
          mb={{
            base: 4,
            md: 8,
          }}
          spacing={2}
          justifyContent="center"
        >
          <Button
            as={GatsbyLink}
            to="/pulpetti"
            colorScheme="accesibleButton"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            w={{
              base: 'full',
              sm: 'auto',
            }}
            mb={{
              base: 2,
              sm: 0,
            }}
            size="lg"
            cursor="pointer"
          >
            Tutustu Pulpetti-verkkotyöpöytään
            <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </Icon>
          </Button>
        </Stack>
      </Box>
      <Box
        w={{
          base: 'full',
          md: 7 / 12,
        }}
        sx={{
          aspectRatio: '16 / 9',
        }}
        display={{
          base: 'none',
          md: 'inherit',
        }}
        mx="auto"
        textAlign="center"
      >
        <Image
          w="full"
          h="full"
          rounded="lg"
          shadow="2xl"
          src={`${data.heroImage.file.url}?fm=webp`}
          alt="Ritta Pulpetti-työpöydän kuvakaappaus"
          loading="lazy"
        />
        <Text
          px={{
            base: 0,
            lg: 24,
          }}
          mb={6}
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="gray.600"
          _dark={{
            color: 'gray.300',
          }}
          pt={5}
        >
          Kuvakaappaus Ritta Pulpetti-verkkotyöpöydästä
        </Text>
      </Box>
    </Box>
  );
}
