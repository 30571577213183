import React, { ReactElement, ReactNode } from 'react';
import {
  chakra, Box, Icon, Flex, Stack, Link,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

function Feature(props: { icon: ReactElement;
  title: string;
  iconSize?: number;
  children: ReactNode }) {
  return (
    <Flex>
      <Flex shrink={0}>
        <Flex
          alignItems="center"
          justifyContent="center"
          h={12}
          w={12}
          rounded="md"
          _light={{
            bg: '#00805e',
          }}
          color="white"
        >
          <Icon
            boxSize={6}
            fill="none"
            viewBox={`0 0 ${props.iconSize || 24} ${props.iconSize || 24}`}
            stroke="currentColor"
            aria-hidden="true"
          >
            {props.icon}
          </Icon>
        </Flex>
      </Flex>
      <Box ml={4}>
        <chakra.dt
          fontSize="lg"
          fontWeight="medium"
          lineHeight="6"
          _light={{
            color: 'gray.900',
          }}
        >
          {props.title}
        </chakra.dt>
        <chakra.dd
          mt={2}
          color="gray.600"
          _dark={{
            color: 'gray.400',
          }}
        >
          {props.children}
        </chakra.dd>
      </Box>
    </Flex>
  );
}

Feature.defaultProps = {
  iconSize: 24,
};

export default function FeatureThree() {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      px={{
        md: 20,
      }}
      pb={{
        md: 20,
      }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        bg="white"
        id="content"
        _dark={{
          bg: 'gray.800',
        }}
        padding={{
          base: 5,
          md: '3rem',
          lg: 20,
        }}
        shadow={{
          md: 'xl',
        }}
        width="100%"
        borderBottomRadius={{
          md: '25px',
        }}
      >
        <Box
          textAlign={{
            lg: 'center',
          }}
        >
          <chakra.p
            mt={2}
            fontSize={{
              base: '3xl',
              sm: '4xl',
            }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            _light={{
              color: 'gray.900',
            }}
            textAlign="center"
          >
            Luomassa parempia opetuksen verkkopalveluita.
          </chakra.p>
          <chakra.p
            mt={4}
            maxW="5xl"
            fontSize="xl"
            mx={{
              lg: 'auto',
            }}
            color="gray.600"
            textAlign="center"
          >
            Kehitämme tällä hetkellä nykyaikaista opetuksen verkkotyöpöytää.
          </chakra.p>
        </Box>
        <Stack
          justifyContent={{
            base: 'left',
            md: 'center',
          }}
          direction={{
            base: 'column',
            sm: 'row',
          }}
          spacing={2}
          mt={10}
        >
          {/* <Box display="inline-flex" rounded="md" shadow="md">
            <Link
              as={GatsbyLink}
              to="/ritta"
              w="full"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              bg="#00805e"
              color="white"
            >
              Oppilashallintojärjestelmä
            </Link>
          </Box> */}
          <Box ml={3} display="inline-flex" rounded="md" shadow="md">
            <Link
              as={GatsbyLink}
              to="/pulpetti"
              w="full"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              bg="#00805e"
              color="white"
            >
              Pulpetti (verkkotyöpöytä)
            </Link>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
}
