import { Box } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import * as React from 'react';
import AppTemplate from '../components/AppTemplate';
// import FeatureImages from '../components/RittaSections/FeatureImages';
import Hero from '../components/HomeSections/Hero';
import FeatureThree from '../components/HomeSections/FeatureThree';
import useOnScreen from '../hooks/useOnScreen';
import ShowOnBreakPoint from '../components/ShowOnBreakpoint';
import FeatureFour from '../components/HomeSections/FeatureFour';

// markup
function RittaPage({ data }: any) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [triggered, setTriggered] = React.useState(false);
  const isVisible = useOnScreen(ref);

  React.useEffect(() => {
    if (triggered) return;
    if (isVisible === true) {
      setTriggered(true);
      if (!(window as any).splitbee) return;
      (window as any).splitbee.track('Scroll to SaaS Feature');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <AppTemplate
      navigation={data.contentfulNavigation}
      SEO={{
        title: 'Ritta - Nykyaikaisen opetuksen tukena',
        description: 'Ritta kehittää helppokäyttöisiä ohjelmistoja nykyaikaisen opetuksen helpottamiseksi.',
        author: 'Ritta.fi',
        pathname: '/',
      }}
    >
      <Hero data={data.contentfulLandingPage} />
      <ShowOnBreakPoint breakpoint="md">
        <Box
          sx={{
            background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,243,248,1) 100%)',
            height: '300px',
            width: '100%',
          }}
          pos="relative"
          zIndex={-1}
          top={-200}
          marginBottom={-200}
        />
      </ShowOnBreakPoint>
      <FeatureThree />
      <FeatureFour />
      <Box
        sx={{
          background: 'linear-gradient(180deg, #edf3f8 0%, var(--chakra-colors-gray-50) 100%)',
          height: '45px',
          width: '100%',
        }}
      />
    </AppTemplate>
  );
}

export const query = graphql`
  query indexQuery($locale: String) {
    contentfulLandingPage(
      contentful_id: {eq: "6QiJ1HJLc4ZE7CHGDvaZeF"}
      node_locale: { eq: $locale }
    ) {
      heroTitle {
        raw
      }
      heroSubtitle {
        raw
      }
      heroImage {
        file {
  
        url}
      }
    }

    contentfulNavigation(
      contentful_id: {eq: "39n107RjyAszi5xLee3gMS"}
      node_locale: {eq: $locale}
    ) {
      title
      navigationOptions {
        ... on ContentfulNavigationLink {
          title
          url
          __typename
        }
 
      }
      loginText
    }
  }
`;

export default RittaPage;
