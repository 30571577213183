import React, { ReactElement } from 'react';
import {
  chakra, Box, Icon, Flex, SimpleGrid,
} from '@chakra-ui/react';

function Feature(props: { icon: ReactElement;
  title: string;
  children: ReactElement | ReactElement[] | string }) {
  return (
    <Box>
      <Icon
        boxSize={12}
        _light={{
          color: '#006634',
        }}
        mb={4}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {props.icon}
      </Icon>
      <chakra.h2
        mb={3}
        fontSize="lg"
        lineHeight="shorter"
        fontWeight="bold"
        _light={{
          color: 'gray.900',
        }}
      >
        {props.title}
      </chakra.h2>
      <chakra.p
        lineHeight="tall"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}
      >
        {props.children}
      </chakra.p>
    </Box>
  );
}

export default function FeatureThree() {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      px={{
        md: 20,
      }}
      pt={{
        base: 0,
        lg: 10,
      }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        spacing={20}
        px={{
          base: 10,
          lg: 16,
          xl: 24,
        }}
        py={20}
        mx="auto"
        bg="white"
        _dark={{
          bg: 'gray.800',
        }}
        borderTopRadius="25px"
        shadow="xl"
        width="100%"
      >
        <Feature
          title="Kyllästyimme huonoihin käyttöliittymiin"
          icon={(
            <>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
            </>
          )}
        >
          Opetuksen ohjelmistojen käyttöliittymät ja sovellukset ovat mielestämme huonoja,
          joten aloimme itse kehittämään parempia ohjelmistoja.
        </Feature>

        <Feature
          title="Paremmat rajapinnat"
          icon={(
            <>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 21l-8 -4.5v-9l8 -4.5l8 4.5v4.5" />
              <path d="M12 12l8 -4.5" />
              <path d="M12 12v9" />
              <path d="M12 12l-8 -4.5" />
              <path d="M15 18h7" />
              <path d="M19 15l3 3l-3 3" />
            </>
          )}
        >
          Emme halua rajoittaa käyttäjien tai kuntien mahdollisuutta integroida
          järjestelmiämme muihin järjestelmiin ja sovelluksiin luomalla
          omia outoja kyselykieliä tai suljettuja rajapintoja.
        </Feature>

        <Feature
          title="Nykyaikaisten teknologioiden avulla"
          icon={(
            <>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="3" y="4" width="18" height="8" rx="3" />
              <path d="M12 20h-6a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3h10.5" />
              <circle cx="18.001" cy="18" r="2" />
              <path d="M18.001 14.5v1.5" />
              <path d="M18.001 20v1.5" />
              <path d="M21.032 16.25l-1.299 .75" />
              <path d="M16.27 19l-1.3 .75" />
              <path d="M14.97 16.25l1.3 .75" />
              <path d="M19.733 19l1.3 .75" />
              <path d="M7 8v.01" />
              <path d="M7 16v.01" />
            </>
          )}
        >
          Käytämme nykyaikaisia, tietoturvallisia sekä nopeita teknologioita palveluidemme
          toteutukseen. Palvelimemme käyttävät 100% vihreää sähköä.
        </Feature>
      </SimpleGrid>
    </Flex>
  );
}
